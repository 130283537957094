import classnames from "classnames";
import React from "react";
import PropTypes from "prop-types";
import useInView from "use-in-view";

import Container from "@components/Container";
import Image from "@components/Image";
import Video from "@components/Video";

import "./style.css";

const DEFAULT_MEDIA_TYPE = "image";
const DEFAULT_HEADER_COLOR = "auto";

const CareersGallery = ({ acf, galleryData }) => {
  const [ref, isInView] = useInView();

  const gallery = galleryData;

  const firstColMediaUrl = gallery[0]?.media?.mediaItemUrl ?? "";
  const firstColMediaType = gallery[0]?.media?.mediaType ?? DEFAULT_MEDIA_TYPE;

  const secondColTopMediaUrl = gallery[1]?.media?.mediaItemUrl ?? "";
  const secondColTopMediaType =
    gallery[1]?.media?.mediaType ?? DEFAULT_MEDIA_TYPE;

  const secondColBottomMediaUrl = gallery[2]?.media?.mediaItemUrl ?? "";
  const secondColBottomMediaType =
    gallery[2]?.media?.mediaType ?? DEFAULT_MEDIA_TYPE;

  const thirdColTopMediaUrl = gallery[3]?.media?.mediaItemUrl ?? "";
  const thirdColTopMediaType =
    gallery[3]?.media?.mediaType ?? DEFAULT_MEDIA_TYPE;

  const thirdColBottomMediaUrl = gallery[4]?.media?.mediaItemUrl ?? "";
  const thirdColBottomMediaType =
    gallery[4]?.media?.mediaType ?? DEFAULT_MEDIA_TYPE;

  const fourthColMediaUrl = gallery[5]?.media?.mediaItemUrl ?? "";
  const fourthColMediaType = gallery[5]?.media?.mediaType ?? DEFAULT_MEDIA_TYPE;

  const FirstColMedia =
    firstColMediaType === DEFAULT_MEDIA_TYPE ? Image : Video;
  const SecondColTopMedia =
    secondColTopMediaType === DEFAULT_MEDIA_TYPE ? Image : Video;
  const SecondColBottomMedia =
    secondColBottomMediaType === DEFAULT_MEDIA_TYPE ? Image : Video;
  const ThirdColTopMedia =
    thirdColTopMediaType === DEFAULT_MEDIA_TYPE ? Image : Video;
  const ThirdColBottomMedia =
    thirdColBottomMediaType === DEFAULT_MEDIA_TYPE ? Image : Video;
  const FourthColMedia =
    fourthColMediaType === DEFAULT_MEDIA_TYPE ? Image : Video;

  const headerColor = acf?.careersGallery_headerColor ?? DEFAULT_HEADER_COLOR;

  return (
    <div
      ref={ref}
      className={classnames("careers-gallery", `header-color--${headerColor}`, {
        "careers-gallery--active": isInView,
      })}
    >
      <Container variant="small">
        <div className="gallery-container">
          <div className="col-1">
            <div className="col-1-media">
              <FirstColMedia
                src={firstColMediaUrl}
                objectPosition="center"
                autoPlay
                loop
              />
            </div>
          </div>
          <div className="col-2">
            <div className="col-2-top-media">
              <SecondColTopMedia
                src={secondColTopMediaUrl}
                objectPosition="center"
                autoPlay
                loop
              />
            </div>
            <div className="col-2-bottom-media">
              <SecondColBottomMedia
                src={secondColBottomMediaUrl}
                objectPosition="center"
                autoPlay
                loop
              />
            </div>
          </div>
          <div className="col-3">
            <div className="col-3-top-media">
              <ThirdColTopMedia
                src={thirdColTopMediaUrl}
                objectPosition="center"
                autoPlay
                loop
              />
            </div>
            <div className="col-3-bottom-media">
              <ThirdColBottomMedia
                src={thirdColBottomMediaUrl}
                objectPosition="center"
                autoPlay
                loop
              />
            </div>
          </div>
          <div className="col-4">
            <div className="col-4-media">
              <FourthColMedia
                src={fourthColMediaUrl}
                objectPosition="center"
                autoPlay
                loop
              />
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

CareersGallery.propTypes = {
  acf: PropTypes.object.isRequired,
};

export default CareersGallery;
