import React, { useRef, useState, useContext } from "react";
import { graphql } from "gatsby";
import gql from "graphql-tag";
import classnames from "classnames";
import useInView from "use-in-view";
import { Helmet } from "react-helmet";

//import { ReactComponent as Close } from "@assets/images/close.svg";
import minusSymbol from "@assets/images/minus-symbol.svg";
import plusSymbol from "@assets/images/plus-symbol.svg";
//import redMinusSymbol from "@assets/images/red-minus-symbol.svg";
import AnimatedText from "@components/AnimatedText";
import CareersGallery from "@components/CareersGallery";
//import Container from "@components/Container";
import Grid from "@components/Grid";
import FadeInOnView from "@components/FadeInOnView";
import FadeLink from "@components/FadeLink";
import Footer from "@components/Footer";
import Image from "@components/Image";
import useUIColorCheck from "@utils/useUIColorCheck";

import useIsMedia from "@utils/useIsMedia";

import ModalContext from "@components/ModalContext";

import "./style.css";

const Careers = ({ data, transitionStatus }) => {
  const modal = useContext(ModalContext);

  const openModalJob = () => {
    modal.setIsOpen({ isOpen: true, typeForm: "job" });
  };

  const openModalContact = () => {
    modal.setIsOpen({ isOpen: true, typeForm: "contact" });
  };

  useUIColorCheck([transitionStatus === "entered"]);

  const { isMobile } = useIsMedia();

  const [headingRef, isHeaderInView] = useInView();
  const [ctaRef, isCTAInView] = useInView();

  const galleryData = data?.wpgraphql?.page?.acf?.gallery;

  const [open] = useState([]);

  const getPositions = () => {
    return data?.wpgraphql?.openPositions?.nodes;
  };

  const openPositionsSection = useRef();
  const detailsRefs = useRef([]);
  const buttonsRefs = useRef([]);
  const titlesRefs = useRef([]);
  const currentPositionRefs = useRef([]);

  const handlePanel = (event, index) => {
    event.stopPropagation();

    var details = detailsRefs.current[index];
    var button = buttonsRefs.current[index];
    var title = titlesRefs.current[index];

    //get height of top row title
    //let positionRowTop = currentPositionRefs.current[index];
    //let RowTopHeight = positionRowTop.scrollHeight;
    //get height of bottom row title
    //let positionRowBottom = currentPositionRefs.current[index + 1];
    //let RowBottomHeight = positionRowBottom?.scrollHeight;

    let innerHeight = details.scrollHeight;

    //get view port height
    //let vh = window.innerHeight;

    //define the height of the panel
    /*   let contentHeight = !isMobile
        ? RowBottomHeight
          ? vh - RowTopHeight - RowBottomHeight
          : vh - RowTopHeight
        : innerHeight; */

    if (open.includes(index)) {
      details.style.height = null;
      button.children[0].src = plusSymbol;
      title.style.color = null;
      scrollBackToPosition(index);
      open.splice(open.indexOf(index), 1);
    } else {
      //details.scrollTop = 0;
      details.style.height = `${/* contentHeight */ innerHeight}px`;
      button.children[0].src = minusSymbol;
      title.style.color = `#FFB0B0`;
      open.push(index);
      //close the other panel if are open
      if (open.length > 1) {
        open.forEach(item => {
          if (item !== index) {
            let details = detailsRefs.current[item];
            let button = buttonsRefs.current[item];
            let title = titlesRefs.current[item];
            details.style.height = null;
            button.children[0].src = plusSymbol;
            title.style.color = null;
            open.splice(open.indexOf(item), 1);
          }
        });
        /* setTimeout(() => {
          scrollBackToPosition(index);
        }, 700); */
      } else {
        //scrollBackToPosition(index);
      }
    }
  };

  const scrollToSection = () => {
    openPositionsSection.current.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
  };

  const scrollBackToPosition = index => {
    currentPositionRefs.current[index].scrollIntoView({ behavior: "smooth" });
  };

  /*  const applyToPosition = position => {
     window.location.href =
       "mailto:hello@rollstudio.co.uk?subject=Application for " +
       position.acf.name;
   }; */

  const openPositions = getPositions().map((position, index) => {
    return (
      <>
        <div
          ref={ref => {
            openPositionsSection.current = ref;
          }}
        ></div>
        <div
          key={index}
          className="position-wrapper"
          ref={ref => {
            currentPositionRefs.current.push(ref);
          }}
        >
          <div
            className={classnames(
              "position-row",
              "cursor-color--white",
              "cursor-color"
            )}
            onClick={e => handlePanel(e, index)}
            role="presentation"
          >
            <Grid.Container fluid>
              <Grid.Row>
                <Grid.Cell mobileColumns={3} columns={11} offset={0}>
                  <div className="position-info">
                    <p
                      className="title"
                      ref={ref => {
                        titlesRefs.current.push(ref);
                      }}
                    >
                      {position.acf.name}
                    </p>
                    <div className="subtitle">
                      <p>{position.acf.type}</p>
                      <p>{position.acf.location}</p>
                      <p>{position.acf.employmenttype}</p>
                    </div>
                  </div>
                </Grid.Cell>
                <Grid.Cell mobileColumns={1} columns={1} offset={0}>
                  <div
                    className="symbol-container"
                    ref={ref => {
                      buttonsRefs.current.push(ref);
                    }}
                  >
                    <Image className="plus-symbol" src={plusSymbol} />
                  </div>
                </Grid.Cell>
              </Grid.Row>
            </Grid.Container>
          </div>
          <div
            className={classnames(
              "details-panel",
              "cursor-type--apply",
              "cursor-type"
            )}
            ref={ref => {
              detailsRefs.current.push(ref);
            }}
            //onClick={() => applyToPosition(position)}
            role="presentation"
          >
            <div className="details-wrapper">
              <Grid.Container fluid>
                <Grid.Row>
                  <Grid.Cell mobileColumns={4} columns={8} offset={0}>
                    <div
                      className="details-content"
                      dangerouslySetInnerHTML={{ __html: position.acf.details }}
                    />
                    <div
                      className={classnames(
                        "symbol-container",
                        "cursor-type--none",
                        "cursor-type"
                      )}
                      onClick={e => handlePanel(e, index)}
                      role="presentation"
                    ></div>
                  </Grid.Cell>
                  <Grid.Cell mobileColumns={4} columns={4} offset={0}>
                    <div className="cta-button-wrapper">
                      <button
                        className="apply-cta"
                        // href={`mailto:hello@rollstudio.co.uk?subject=Application for ${position.acf.name}`}
                        onClick={openModalJob}
                      >
                        Apply now
                      </button>
                    </div>
                  </Grid.Cell>
                </Grid.Row>
              </Grid.Container>
            </div>
          </div>
        </div>
      </>
    );
  });

  const pageTitle = data?.wpgraphql?.page.metaTagsAcf.pageTitle
    ? data?.wpgraphql?.page.metaTagsAcf.pageTitle +
      " | " +
      data?.site?.siteMetadata.title
    : data?.wpgraphql?.page.title;
  const pageDescription = data?.wpgraphql?.page?.metaTagsAcf?.pageDescription;
  const pageImage = data?.wpgraphql?.page?.metaTagsAcf?.pageImage?.mediaItemUrl;

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <meta property="og:title" content={pageTitle} />
        <meta property="og:description" content={pageDescription} />
        {pageImage && <meta property="og:image" content={pageImage} />}
      </Helmet>

      <section className="careers-page__inner">
        <Grid.Container fluid>
          <div
            ref={headingRef}
            className={classnames("careers-page__heading", {
              "careers-page__heading--active": isHeaderInView,
            })}
          >
            <h4 className="careers-page__heading-title">
              <span>Careers</span>
            </h4>
            <h2 className="careers-page__heading-subtitle-first-line">
              <span>Pixel pushers and creative coders,</span>
            </h2>
            <button
              className={classnames("cursor-type--anchor", "cursor-type")}
              onClick={() => scrollToSection()}
            >
              <h2 className="careers-page__heading-subtitle-second-line">
                <span>come join our digital family</span>
              </h2>
            </button>
          </div>
        </Grid.Container>
        <CareersGallery galleryData={galleryData} />

        <FadeInOnView
          className="careers-page__positions"
          ref={openPositionsSection}
        >
          {openPositions}
        </FadeInOnView>

        <div
          onClick={openModalContact}
          ref={ctaRef}
          className="careers-page__cta"
        >
          <Grid.Container fluid>
            <FadeLink
              fade
              /*  to="/contact" */
              className={classnames("cursor-type", "cursor-type--contactus")}
            >
              <div className="careers-page__cta-inner">
                <h4>
                  <AnimatedText
                    text={"Can’t see the right fit?"}
                    fadeIn={isCTAInView}
                    delay={240}
                    easing="var(--t--ease-out-quint)"
                    duration={2000}
                  />
                </h4>
                <h2>
                  <AnimatedText
                    text={"Drop us a line"}
                    fadeIn={isCTAInView}
                    delay={240}
                    blockDelay={240}
                    easing="var(--t--ease-out-quint)"
                    duration={2000}
                  />
                </h2>
              </div>
              {/*  <span className="mobile-cta">Contact us</span> */}
            </FadeLink>
          </Grid.Container>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default Careers;

export const query = graphql`
  query($wpId: ID!) {
    site {
      siteMetadata {
        title
      }
    }
    wpgraphql {
      page: page(id: $wpId, idType: DATABASE_ID) {
        title
        acf {
          gallery {
            media {
              mediaItemUrl
              mediaType
            }
          }
        }

        metaTagsAcf {
          pageTitle
          pageDescription
          pageImage {
            mediaItemUrl
          }
        }
      }

      openPositions {
        nodes {
          acf {
            details
            employmenttype
            fieldGroupName
            location
            name
            salary
            type
          }
        }
      }
    }
  }
`;

export const previewQuery = gql`
  query($wpId: ID!) {
    wpgraphql {
      page: page(id: $wpId, idType: DATABASE_ID) {
        preview: revisions(first: 1, before: null) {
          nodes {
            acf {
              gallery {
                media {
                  mediaItemUrl
                  mediaType
                }
              }
            }
          }
        }
      }
    }
  }
`;
